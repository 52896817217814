body {
  background-color: #111;
  color: gray;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 3vh;
}

a,
input,
button {
  border: thin solid #FF07;
  background-color: transparent;
  color: yellow;
  border-radius: 1vh 2vw;
  padding: 1vh 2vw;
}

button{
  font-size: 5vh;
  margin: 1vh 1vw;
  padding: 4vh 3vw;
}
input {
  font-size: 3vh;
}
#progress {
  height: 7vh;
  width: 95%;
}

#controls {
  /* position: fixed;
  left: 0;
  bottom: 0; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
}
#controls div {
  margin: 0;
  padding: 0;
}

#dot {
  position: absolute;
  color: #FF0;
  background-color: #FF02;
  height: 1rem;
  width: 1rem;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

#main {
  position: relative;
  font-family: 'Courier New', Courier, monospace;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5vh;
  padding-top: 10vh;
  color: grey;
}
#pauser {
  font-weight: 900;
}

#prev {
  position: absolute;
  color: grey;
  left: 10%;
  bottom: 25%;
  transform: translateX(-50%);
  font-size: 2.2vh;
}
#word {
  position: absolute;
  background-color: black;
  color: white;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 2vh 2vw;
  font-size: 7vh;
  border-radius: 2rem;
}
#next {
  position: absolute;
  color: grey;
  right: 10%;
  top: 25%;
  transform: translateX(-50%);
  font-size: 2.2vh;
}

.dim {
  opacity: 0.5;
}